<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('机器人列表')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="page-card">
            <div class="page-card_bd">
                <div class="robet-list">
                    <div :class="`robet-card robet-No` + index" v-for="(item, index) in list" :key="index">
                        <div class="robet-pic">
                            <img src="../../assets/pictures/jiqiren1.png" v-if="item.level == 0">
                            <img src="../../assets/pictures/jiqiren2@2x.png" v-if="item.level == 1">
                            <img src="../../assets/pictures/jiqiren3@2x.png" v-if="item.level == 2">
                            <img src="../../assets/pictures/jiqiren4@2x.png" v-if="item.level == 3">
                            <img src="../../assets/pictures/jiqiren5@2x.png" v-if="item.level == 4">
                            <img src="../../assets/pictures/jiqiren6@2x.png" v-if="item.level == 5">
                        </div>
                        <div class="flex_bd">
                            <div class="robet-title">{{$t(item.name)}}</div>
                            <div class="robet-tags">
                                <div class="tag-item">
                                    <span class="p-red">*</span>
                                    <span class="p-text"> {{$t('有效期')}} {{item.days}} {{$t('天')}}</span>
                                </div>
                                <div class="tag-item">
                                    <span class="p-red">*</span>
                                    <span class="p-text"> {{$t('价格')}} {{parseInt(item.price)}} {{$t('USDT')}}
                                        <span class="text-red" v-if="item.price == 0">{{$t('免费')}}</span>
                                    </span>
                                </div>
                                <div class="tag-item">
                                    <span class="p-red">*</span>
                                    <span class="p-text"> {{$t('做单价格')}} {{parseInt(item.min_money)}} {{$t('USDT')}}
                                        <span class="text-red" v-if="item.price == 0">{{$t('免费')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="robet-right">
                            <van-button round  @click="url('robot-no', item)">{{parseFloat(item.price).toFixed(2)}}</van-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Foot :active="0"/>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        components: {
            Foot
        },
        data() {
            return {
                active: '',
                list: []
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
			url(url, item) {
				this.$router.push({
					path: url,
                    query: {id: item.id}
				})
			},
            getData() {
                this.$axios
                    .get('/robot/list')
                    .then((res) => {
                        if (res.data && res.data.code == 0) {
                            this.loading = false;
                            this.list = res.data.data;
                        }
                    }).catch((err) => {
                        console.log(err.message);
                        // this.$toast.fail(err.message);
                    });
            }
        },
        created() {
            this.getData();
        }
    }
</script>
<style scoped="scoped">
    .page {
        min-height: 100vh;
        /*background-image: url(../../assets/pics/Background.png);*/
        /*background-repeat: no-repeat;*/
        /*background-size: 100% auto;*/
        background-color: #FFFFFF;
    }
</style>
